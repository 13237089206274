.OverflowEllispsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.MaxWidthPx300 {
    max-width: 300px;
}

.AdReportTextArea {
    border: solid 2px lightgrey;
}