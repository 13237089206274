/**
* TheMoneytizer CSS Kit 2023-03-07
*/

@import url('https://fonts.googleapis.com/css2?family=Livvic:wght@400;500;600&family=Poppins:wght@400;500;600&display=swap');

.App {
  background: #eff0ff;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
}

.Subtitle {
  font-size: 14px;
}
/*******
* Colors *
********/

.TGreen {
  color: #2ecc71;
}

.TBlack {
  color: #000000;
}

.TWhite {
  color: #ffffff;
}

.TRed {
  color: #f41127;
}

.TPink {
  color: #ff7979;
}

.TPurple {
  color: #6c5ce7;
}

.TYellow {
  color: #f1c40f;
}

.TSky {
  color: #70a1ff;
}

.TGrey {
  color: #898989;
}

.TOrange {
  color: #e67e22;
}

.URed {
  text-decoration-color: #f41127;
}

.BOrange {
  background: #e67e22;
}

.BPurplePink {
  background: #8B3E64;
}

.BBlack {
  background: #222222;
}

.BBlue {
  background: #3498db;
}

.BDarkSaphir {
  background: #0c2461;
}

.BRed {
  background: #f41127;
}

.BSoftRed {
  background: #ffa3ac;
}

.BWhite {
  background: #FFFFFF;
}

.BLightWhite {
  background: #F5F5F5;;
}

.BGreen {
  background: #2ecc71;
}

.BYellow {
  background: #f1c40f;
}

.BPink {
  background: #ff7979;
}

.BLightGrey {
  background: #bdbdbd;
}

.BSoftGreen {
  background: #7bed9f;
}

.BGrey {
  background: #767676;
}

.BPurple {
  background: #6c5ce7;
}

.BSky {
  background: #70a1ff;
}

.BSoftPink {
  background: #EF4D77;
}

.BSoftBlue {
  background: #eff0ff;
}

.BSoftPurple {
  background: #8B3E64;
}

.BDarkPurple {
  background: #660033;
}

.BVerySoftRed {
  background: #f1c2c6;
}

.BVerySoftPurple {
  background: #f6f0fc;
}

.BPixelatedGrass {
  background: #009432;
}

.BSoftGreen {
  background: #c5f1b4;
}

.BSoftYellow {
  background: #fff693;
}

.BSoftRock {
  background: #898989;
}

.BRock {
  background: #3e3e3e;
}

.HalfOpacity {
  opacity: 0.5;
}

/*******
* Helpers *
********/

.BoxSizeBorder {
  box-sizing: border-box;
}

.Hide {
  display: none !important;
}

.P5 {
  padding: 5px;
}

.P10 {
  padding: 10px;
}

.P15 {
  padding: 15px;
}

.P20 {
  padding: 20px;
}

.P25 {
  padding: 25px;
}

.Px10 {
  padding-left: 10px;
  padding-right: 10px;
}

.Py5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.M0 {
  margin: 0px;
}

.MB0 {
  margin-bottom: 0px;
}

.MB5 {
  margin-bottom: 5px;
}

.MB10 {
  margin-bottom: 10px;
}

.MB15 {
  margin-bottom: 15px;
}

.MB20 {
  margin-bottom: 20px;
}

.MB25 {
  margin-bottom: 25px;
}

.MB30 {
  margin-bottom: 30px;
}

.ML0 {
  margin-top: 0px;
}

.ML5 {
  margin-left: 5px;
}

.ML10 {
  margin-left: 10px;
}

.ML15 {
  margin-left: 15px;
}

.ML20 {
  margin-left: 20px;
}

.ML25 {
  margin-left: 25px;
}

.ML30 {
  margin-left: 30px;
}

.MTAuto {
  margin-top: auto;
}

.MT0 {
  margin-top: 0px;
}

.MT5 {
  margin-top: 5px;
}

.MT10 {
  margin-top: 10px;
}

.MT15 {
  margin-top: 15px;
}

.MT20 {
  margin-top: 20px;
}

.MR5 {
  margin-right: 5px;
}

.MR10 {
  margin-right: 10px;
}

.MR15 {
  margin-right: 15px;
}

.MR20 {
  margin-right: 20px;
}

.Gap0 {
  gap: 0px;
}

.Gap5 {
  gap: 05px;
}

.Gap10 {
  gap: 10px;
}

.Gap15 {
  gap: 15px;
}

.Gap20 {
  gap: 20px;
}

.TCenter {
  text-align: center;
}

.TLeft {
  text-align: left;
}

.TRight {
  text-align: right;
}

.GridTemplate12 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(5, auto);
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  padding: 40px;
  box-sizing: border-box;
}

.GridCol1-13 {
  grid-column: 1 / 13;
}

.GridCol1-7 {
  grid-column: 1 / 7;
}

.GridCol7-13 {
  grid-column: 7 / 13;
}

.GridCol1-4 {
  grid-column: 1 / 4;
}

.GridCol3 {
  grid-column: span 3;
}

.BorderRad3 {
  border-radius: 3px;
}

.BorderRad6 {
  border-radius: 6px;
}

.BorderRad10 {
  border-radius: 10px;
}

.BorderRad50 {
  border-radius: 50%;
}

.BorderGrey {
  border: solid 2px #e1e1e1
}


/*******
* Flex *
********/

.FlexRow {
  display: flex;
}

.FlexWrap {
  flex-wrap: wrap;
}

.FlexNoWrap {
  flex-wrap: nowrap;
}

.FlexCol {
  display: flex;
  flex-direction: column;
}

.JustifyBetween {
  justify-content: space-between;
}

.JustifyCenter {
  justify-content: center;
}

.JustifyAround {
  justify-content: space-around;
}

.JustifyEnd {
  justify-content: flex-end;
}

.JustifyStart {
  justify-content: flex-start;
}

.AlignEnd {
  align-items: flex-end;
}

.AlignBaseline {
  align-items: baseline;
}

.WrapContent {
  flex-wrap: wrap;
}

/*******
* Quick Components *
********/

.ActionButton {
  border-radius: 4px;
  color: #FFFFFF;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.ActionButton.Alternate {
  border-radius: 4px;
  color: #FFFFFF;
  padding: 15px 20px;
  border: none;
  cursor: pointer;
}

.BoDateSelector .rdp {
  border-radius: 9px;
  box-shadow: 0 7px 14px rgb(0 0 0 / 5%);
  background: #FFFFFF;
  padding: 10px;
}

.BoDateSelector .rdp-day_selected.rdp-day_selected {
  background: #f41127;
}

.LightButton {
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.ActionButton:hover, .LightButton:hover {
  opacity: 0.8;
}

.ActionInput {
  border: 2px solid #d5d5d5;
  border-radius: 6px;
}

.ActionInput:focus {
  border: 2px solid #9f9f9f;
  outline: none !important;
}

.ActionText {
  border-radius: 3px;
  height: 25px;
  border: 1px solid #A5A5A5;
  outline: none;
}

.ActionLink {
  color: black;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
  text-decoration-color: #f41127;
}

.ActionLink:visited {
  color: black;
}

.ActionSelect {
  border-radius: 3px;
  border: 1px solid black;
}

.SplashLoadCard {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.Pagination {
  display: flex;
  justify-content: center;
  padding: 10px;
  list-style-type: none;
}

.Pagination a {
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid #ddd;
  cursor: pointer;
  text-decoration: none;
  color: #333;
}

.Pagination .active a {
  background-color: #E74C3C;
  color: white;
  border: 1px solid #E74C3C;
}

.Pagination a:hover {
  background-color: #E74C3C;
  color: white;
  border: 1px solid #E74C3C;
}

.BlobGreen {
	background: #2ecc71;
	box-shadow: 0 0 0 0 #2ecc71;
  border-radius: 50%;
	margin: 10px;
	height: 15px;
	width: 15px;

	transform: scale(1);
	animation: pulseGreen 2s infinite;
}

.BlobRed {
	background: rgba(231, 76, 60,1.0);
	box-shadow: 0 0 0 0 rgba(231, 76, 60,1.0);
  border-radius: 50%;
	margin: 10px;
	height: 15px;
	width: 15px;

	transform: scale(1);
	animation: pulseRed 2s infinite;
}

.CursorPointer {
  cursor: pointer;
}

.OverflowAuto {
  overflow: auto;
}

.OverflowWrapBreakWord {
  overflow-wrap: break-word;
}

.WhiteSpacePreWrap {
  white-space: pre-wrap;
}

@keyframes pulseGreen {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(46, 204, 113,0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(46, 204, 113,0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(46, 204, 113,0);
	}
}


@keyframes pulseRed {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(231, 76, 60,0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(231, 76, 60,0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(231, 76, 60,0);
	}
}

/*******
* Width *
********/

.Width0 {
  width: 0%;
}

.Width5 {
  width: 5%;
}

.Width10 {
  width: 10%;
}

.Width15 {
  width: 15%;
}

.Width20 {
  width: 20%;
}

.Width25 {
  width: 25%;
}

.Width30 {
  width: 30%;
}

.Width35 {
  width: 35%;
}

.Width40 {
  width: 40%;
}

.Width45 {
  width: 45%;
}

.Width50 {
  width: 50%;
}

.Width55 {
  width: 55%;
}

.Width60 {
  width: 60%;
}

.Width65 {
  width: 65%;
}

.Width70 {
  width: 70%;
}

.Width75 {
  width: 75%;
}

.Width80 {
  width: 80%;
}

.Width85 {
  width: 85%;
}

.Width90 {
  width: 90%;
}

.Width95 {
  width: 95%;
}

.Width100 {
  width: 100%;
}

.Width200 {
  width: 200%;
}

.WidthFitContent {
  width: fit-content;
}

.WidthPx5 {
  width: 5px;
}

.WidthPx10 {
  width: 10px;
}

.WidthPx15 {
  width: 15px;
}

.WidthPx20 {
  width: 20px;
}

.WidthPx25 {
  width: 25px;
}

.WidthPx30 {
  width: 30px;
}

.WidthPx35 {
  width: 35px;
}

.WidthPx50 {
  width: 50px;
}

.WidthPx75 {
  width: 75px;
}

.WidthPx90 {
  width: 90px;
}

.WidthPx120 {
  width: 120px;
}

.WidthPx185 {
  width: 185px;
}

.MWidthFitContent {
  min-width: fit-content;
}

.MWidthMaxContent {
  min-width: max-content;
}

.MaxWidthPx600 {
  max-width: 600px;
}

/*******
* Height *
********/

.Height10px {
  height: 10px;
}

.Height15px {
  height: 15px;
}

.Height20px {
  height: 20px;
}

.Height25px {
  height: 25px;
}

.Height30px {
  height: 30px;
}

.Height150px {
  height: 150px;
}

.Height300px {
  height: 300px;
}

.Height100 {
  height: 100%;
}

.HeightFitContent {
  height: fit-content;
}

/*******
* Font *
*******/
.FWLight {
  font-weight: lighter;
}

.FWMedium {
  font-weight: normal;
}

.FWBold {
  font-weight: bold;
}

.Capitalize {
  text-transform: capitalize;
}

.FullWidth {
  width: 100%;
}

.AlignCenter {
  align-items: center;
}

.VAlignTop {
  vertical-align: top;
}

.LabelCard.MT5 {
  margin-top: 5px !important;
}

.MediumIcon {
  font-size: 24px;
}

.InBlock {
  display: inline-block;
}

.Inline {
  display: inline;
}

.Block {
  display: block;
}

.HFitContent {
  height: fit-content;
}

.ButtonMiddleSize {
  height: 35px;
}

.HalfWidth {
  width: 50%;
}

h1 {
  font-family: 'Poppins', sans-serif;
}

h5 {
  font-family: 'Livvic', sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: #898989;
}

.SmallFont {
  font-size: 12px;
}

.MediumFont {
  font-size: 18px;
}

.BigFont {
  font-size: 32px;
}

.BSoftyRed {
  background: #ffeded;
}

.Underline {
 text-decoration: underline;
}

.NoUnderline {
  text-decoration: none;
}



.VisibilityButton {
  height: 26px;
}

.VisibilityButton:hover {
  opacity: 0.8;
}

.DarkModeLayer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background: black;
  mix-blend-mode: difference;
}

.AlignStart {
  align-items: flex-start;
}

h5.RegularTitle {
  font-size: 16px;
}

.RegularTitle {
  color: #222222;
  font-weight: lighter;
}

.LightText {
  font-weight: lighter;
  font-size: 12px;
}

.ShadowContainer {
  border-radius: 9px;
  box-shadow: 0 7px 14px rgb(0 0 0/5%); /* Updated here, previous was box-shadow: 0px 1px 3px rgb(0 0 0 / 8%); */
}

/* table th {
  font-weight: lighter;
} */

table thead tr th {
  font-weight: 800;
}


.LiteLoad:empty {
  width: 100%;
  height: 15px;
  border-radius: 3px;
  display: block;
  background: linear-gradient(
      to right,
      rgb(196, 213, 253),
      rgba(255, 255, 255, 0.5) 50%,
      rgb(196, 213, 253) 80%
    ),
    rgb(196, 213, 253);
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}

.ReadOnlyText:not(:empty) {
  background: #eff0ff;
  padding: 5px;
  border-radius: 3px;
}

@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}

.VerticalSeparator {
  border-left: 1px solid #bfc4c6;
  width: 1px;
  height: auto;
}

/* POP UP */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  max-width: 95vw; 
  max-height: 90vh; 
  background: white; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.popup-content-wrapper {
  padding: 20px;
  overflow-y: auto; 
  flex: 1; 
  max-height: calc(90vh - 100px); 
}

.popup-footer {
  padding: 15px 20px;
  border-top: 1px solid #eee;
  text-align: right;
  background: white;
  border-radius: 0 0 10px 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.FormSelect {
  padding: 5px;
  min-width: 100px;
}