:root {
    --background: #f8f8f8;
    --dark: #303032;
    --liquid: blue;
  }
  
  
  .SplashLoad * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .SplashLoad .SubTitle {
    margin-top: -30px;
    color: #ffffff;
    z-index: 9999;
  }
  
  .SplashLoad {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .SplashLoad.Alt {
    min-height: unset;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
  }
  
  .SplashLoad .box {
    border-radius: 50%;
    position: relative;
    background: #f41127;
    border: 0.25rem solid #f41127;
    height: 8rem;
    width: 8rem;
    outline: 0;
    overflow: hidden;
  }
  
  .SplashLoad .box::before {
    content: url(/public/themoneytizer.svg);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .SplashLoad .box::after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: -50%;
    height: 200%;
    width: 200%;
    background-color: var(--background);
    border-radius: 35%;
    animation: spin 3s ease-in-out infinite;
  }

  @keyframes spin {
    0% {
      transform: translateY(0) rotate(0deg);
    }
    100% {
      transform: translateY(-100%) rotate(400deg);
    }
  }
  