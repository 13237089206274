/* Dropdown */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: max-content;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 0px;
    
}

.dropdown-content button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    appearance: none;
    outline: none;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.dropdown-content button:first-child:hover {
    background-color: #f1f1f1;
    border-top-left-radius: 6px; 
    border-top-right-radius: 6px;
}

.dropdown-content button:last-child:hover {
    background-color: #f1f1f1;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.dropdown-content button:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown .disabled {
    background-color: #ddd;
    color: #999;
    cursor: not-allowed;
}

.dropdown.disabled .dropdown-content {
    display: none;
}
