.NotificationList tbody tr:nth-child(odd) {
    background: #f2ebf1;
}

.NotificationList .popup-content-wrapper {
    padding: 20px;
    max-width: 800px;
    width: 100%;
}

.NotificationList .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.NotificationList .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 1001;
    max-height: 90vh;
    overflow-y: auto;
}