.CAChart {
    max-height: 400px;
}

.CAChart .InnerCAChart {
    max-height: 300px;
}

.CAChart #CAOfTheMonth {
    margin-right: 50px;
}

.SmallText {
    font-size: 0.8em;
    font-weight: normal;
}

.Gray {
    color: #666;
}

.ML10 {
    margin-left: 10px;
}