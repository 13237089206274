.SortableItemTopGeo {
    padding: 5px;
    border: 1px solid #222222;
    border-radius: 3px;
}

.SortableItemTopGeo img {
    width: 45px;
}

.SortableItemTopGeo {
    cursor: grab;
}

.SortableItemTopGeo .DragHandle {
    cursor: grab;
    padding-right: 10px;
    display: inline-block;
}