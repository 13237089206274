.Translation table tbody tr {
    font-size: 14px;
}

.Translation table tbody tr td {
    padding: 10px;
}

.Translation table tbody tr textarea {
    min-width: 33vw;
    min-height: 100px;
    height: fit-content;
}

.Translation tbody tr:nth-child(odd){
    background: #f2ebf1;
  }