.loginComponent {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(60vh - 60px);
}
.FormContainer {
    height: 250px;
    width: 400px;
    background: #FFFFFF;
    padding: 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.FormContainer > h2 {
    text-align: center;
}

.FormContainer input {
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    border: none;
}

.FormContainer input:focus{
    outline: none;
}

.FormContainer .InputContainer {
    height: 30px;
    width: 75%;
    margin: 10px;
    border: 1px solid #898989;
    border-radius: 3px;
}

.FormContainer .ActionButton {
    width: 80px;
}

.CheckboxContainer input {
    width: 20px;
}