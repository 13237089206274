.OverviewPlateform .TruncateText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    display: block;
    color: black;
    text-decoration-color: red;
}

.OverviewPlateform .Tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.OverviewPlateform .Tooltip .Tooltiptext {
    visibility: hidden;
    width: fit-content;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.Tooltip .Tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}
  
.Tooltip:hover .Tooltiptext {
    visibility: visible;
    opacity: 1;
}

.FilterOverview:hover {
    opacity: 0.75;
}