.PartnersPerfTable {
    width: 100%;
}

.PartnersPerfTable th {
    background: #ec2121;
    color: #FFFFFF;
    padding: 5px;
}

.PartnersPerfTable tr td:not(td:first-child) {
    text-align: center;
    font-size: 14px;
}

.PartnersPerfTable tr td:first-child,
.PartnersPerfTable tr td.bold,
.PartnersPerfTable .partner_name {
    font-weight: bold;
}

.PartnersPerfTable .more_100_percent {
    background-color: #27c24c;
    color: black;
    font-weight: bold;
}

.PartnersPerfTable .less_40_percent {
    background-color: orange;
    color: black;
    font-weight: bold;
}

.PartnersPerfTable .no_total {
    background-color: red;
}

.PartnersPerfTable tbody tr:nth-child(odd) {
    background: #f2ebf1;
}

.DayPickerContainer {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.DayPickerObject {
    --rdp-accent-color: #f41127;
    --rdp-background-color: #f41127;
}

.rdp-day_selected:not([disabled]), 
.rdp-day_selected:focus:not([disabled]), 
.rdp-day_selected:active:not([disabled]), 
.rdp-day_selected:hover:not([disabled]) {
    background-color: #f41127;
    color: white;
}

.rdp-day_range_start:not([disabled]),
.rdp-day_range_end:not([disabled]) {
    background-color: #f41127 !important;
    color: white;
}

.rdp-day_range_middle:not([disabled]) {
    background-color: rgba(244, 17, 39, 0.1) !important;
    color: #f41127;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: rgba(244, 17, 39, 0.1);
    color: #f41127;
}