#notificationSiteId,
#notificationUserId,
#notification_lang,
#notification_sous_type,
#notification_message_area{
    width: 50%;
}

.NotificationSetup {
    width: 100%;
    max-width: 800px;
}

.notification-setup-header {
    padding: 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notification-setup-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #666;
}

.notification-setup-content {
    padding: 20px;
    max-height: calc(90vh - 150px);
    overflow-y: auto;
}

.form-section {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
}

.radio-group {
    display: flex;
    gap: 20px;
    align-items: center;
}

.radio-group label {
    margin-left: 5px;
}

.NotificationSetup .ActionInput {
    width: 100%;
    padding: 8px 12px;
}

.NotificationSetup .ActionInput:focus {
    border-color: #2196F3;
    outline: none;
}

select.ActionInput {
    background-color: white;
}

textarea.ActionInput {
    min-height: 100px;
    resize: vertical;
}

.notification-setup-footer {
    padding: 20px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    position: sticky;
    bottom: 0;
    background: white;
}

/* Styles pour les cases à cocher */
.checkbox-group {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 5px;
}

/* Style pour les champs désactivés */
.ActionInput:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

/* Animation d'apparition */
.NotificationSetup {
    animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}