.Header {
    background: #ec2121;
    padding: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    top: 0px;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 9999;
}

.Header > img {
    height: 30px;
}

.HeaderSpacer {
    margin-top: 60px;
    border: 0px;
    width: 100%;
}