.PendingApprovalItemComponent {
    padding: 25px 10px;
    border-radius: 3px;
    margin-top: 10px;
    background: #eff0ff
}

.PendingApprovalItemComponent .Mismatch {
    background: #ff6b81;
    border-radius: 3px;
    height: 30px;
    padding: 2px;
}

.PendingApprovalItemComponent .Identical {
    background: #7bed9f;
    border-radius: 3px;
    height: 30px;
    padding: 2px;
}

.PendingApprovalItemComponent .ProofPicture {
    width: 150px;
}

.PendingApprovalItemComponent .FullScreenPreview {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.PendingApprovalItemComponent .FullScreenPreview button {
    position: fixed;
    top: 5px;
    right: 5px;
}

.PendingApprovalItemComponent .FullScreenPreview img {
    max-width: 100%;
    max-height: 100%;
}